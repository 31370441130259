import { useState, useEffect } from 'react';
import { CreateReceipt, Receipt, ReceiptType } from 'types';
import { ReceiptApi } from 'api';

import { getCaseByUserId } from 'api/case';
import * as logger from 'api/logger';

export interface ReceiptsState {
  loading: boolean;
  receipts: Array<Receipt>;
  receiptTypes: Array<ReceiptType>;
  finalizeReceipt(id, params): void;
  createReceipt(value, paymentRows, invoiceRows): Promise<any>;
  pushReceiptToS3(signedRequest, document): Promise<any>;
  getReceipts(order?: string): Promise<void>;
  sendReceipt(id: number): Promise<void>;
  deleteReceipt(id: number): Promise<void>;
  getReceiptUrl(id: number): Promise<string>;
  hardDeleteReceipt(id: number): Promise<void>;
  fetchReceiptTypes: () => void;
}

function useReceiptApi(patientId?: number): ReceiptsState {
  const [caseId, setCaseId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState<Array<Receipt>>([]);
  const [receiptTypes, setReceiptTypes] = useState<Array<ReceiptType>>([]);

  async function getReceipts(order = 'desc'): Promise<void> {
    if (!patientId) return;
    setLoading(true);

    try {
      const cases = await getCaseByUserId(patientId);
      if (cases.length) {
        setCaseId(cases[0].id);
        const receipts = await ReceiptApi.getReceipts(cases[0].id, order);
        setReceipts(receipts);
      }
    } catch (e) {
      logger.error('useReceiptApi ' + e.message);
    }
    setLoading(false);
  }

  async function fetchReceiptTypes(): Promise<void> {
    const response = await ReceiptApi.getReceiptTypes();
    setReceiptTypes(response);
  }

  async function createReceipt(value, paymentRows, invoiceRows): Promise<void> {
    setLoading(true);
    let receiptData;

    if(value.refund) {
      value.total = -1 * Math.abs(value.total);
    }

    try {
      value.paymentRows = paymentRows;
      value.invoiceRows = invoiceRows;
      receiptData = await ReceiptApi.createReceipts(caseId, value);
    } catch (err) {
      logger.error(err.message);
    }
    setLoading(false);
    return receiptData;
  }

  async function pushReceiptToS3(signedRequest: string, document) {
    setLoading(true);
    let addedToS3;
    try {
      addedToS3 = await ReceiptApi.pushReceiptToS3(signedRequest, document);
    } catch (err) {
      logger.error(err.message);
    }
    setLoading(false);
    return addedToS3;
  }

  async function finalizeReceipt(id: number, params) {
    setLoading(true);
    let finalReceipt;

    try {
      finalReceipt = await ReceiptApi.completeRecieptCreation(id, { ...params, pdfCreated: true });
      if (finalReceipt) {
        setReceipts([finalReceipt, ...receipts]);
      }
    } catch (err) {
      logger.error(err.message);
      try {
        await ReceiptApi.completeRecieptCreation(id, { pdfCreated: false });
      } catch (err2) {
        logger.error(err2.message);
      }
    }
    setLoading(false);
    return finalReceipt;
  }

  async function sendReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.sendReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  async function getReceiptUrl(id: number): Promise<string> {
    const result = await ReceiptApi.getTemporaryReceiptUrl(id);
    return result.signedRequest;
  }

  async function deleteReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.deleteReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  async function hardDeleteReceipt(id): Promise<void> {
    setLoading(true);
    try {
      await ReceiptApi.hardDeleteReceipt(caseId, id);
      await getReceipts();
    } catch (e) {
      logger.error(e.message);
    }
    setLoading(false);
  }

  return {
    loading,
    receipts,
    fetchReceiptTypes,
    receiptTypes,
    getReceiptUrl,
    finalizeReceipt,
    pushReceiptToS3,
    getReceipts,
    createReceipt,
    sendReceipt,
    deleteReceipt,
    hardDeleteReceipt,
  };
}

export default useReceiptApi;
