import React, { ReactElement } from 'react';
import { AppProvider } from 'context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider as AuthProvider } from 'context/auth';
import { Provider as ProfileProvider } from 'context/profile';
import Routing from './routing';

import AppWrapper from 'Layout/AppWrapper';

import './App.scss';

function App(): ReactElement {
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppProvider>
          <AuthProvider>
            <ProfileProvider>
              <AppWrapper>
                <Routing />
              </AppWrapper>
            </ProfileProvider>
          </AuthProvider>
        </AppProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}

export default App;
